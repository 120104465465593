import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Commercial Jazz - Παιδικό Τμήμα";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Το μάθημα αυτό συνδυάζει στοιχεία από πολλά είδη χορού (όπως hip-hop,
          jazz, waacking κ. α) και χαρακτηρίζεται από εναλλαγές διαφορετικών
          δυναμικών.
        </P>
        <P>
          Είναι ένας έντονος, εκρηκτικός χορός, που βοηθάει τους μαθητές να
          διασκεδάσουν, να εκτονωθούν, να εκφραστούν, να αφεθούν, να αναπτύξουν
          την αυτοπεποίθησή τους και τη μουσικότητά τους, καθώς και να μάθουν να
          δουλεύουν με σωστές τεχνικές κινησιολογίας. Το μάθημα συνδυάζει τόσο
          χορογραφία όσο και αυτοσχεδιασμό.
        </P>
      </Section.Main>
    </Layout>
  );
}
